body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Drum Machine */

.header-row,
.row {
  display: flex;
}

.header-label,
.row-label {
  width: 50px;
  /* Width of the label cells */
  text-align: center;
  /* Additional styling as needed */
}

.header-cell,
.cell {
  width: 23px;
  /* Width of the grid cells */
  height: 20px;
  background-color: #fff;
  cursor: pointer;
}

.cell {
  border: 1px solid black;
  width: 20px;
}

.cell.active {
  background-color: blue;
}

.cell.current-step {
  border: 2px solid red;
}